.advent {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 60px 0 112px 0;

    &-box {
        display: flex;
        flex-direction: column;
        gap: 36px;

        &__item {
            display: flex;
            flex-direction: row;
            gap: 16px;
            align-items: center;

            &-icon {
                width: 56px;
                height: 56px;
            }

            &-text {
                font-size: 23px;
                font-weight: 500;
                line-height: 28px;
                color: #555;
            }
        }
    }
}
.advent-box__item-icon {
    width: 56px;
    height: 56px;
}

.hidden {
    display: none;
}

@media screen and (max-width: 1440px) {
    .advent-box {
        width: 30%;
    }
}
@media screen and (max-width: 1000px) {
.advent {
    flex-direction: column;
    gap: 24px;
    width: 100%;
}
}

@media screen and (max-width: 820px) {
    .advent-box {
        width: 100%;
        gap: 24px;
    }
}