.build-status {

    &__title {
        font-size: 32px;
        font-weight: 500;
        line-height: 40px;
        letter-spacing: -0.35px;
        color: #181E1A;
        margin-top: 60px;
    }

    &__actions {
        display: flex;
        flex-direction: row;
        gap: 20px;
        padding-top: 32px;

        &-button {
            width: 190px;
            height: 44px;
            padding: 10px 16px;
            border-radius: 30px;
            background-color: #fff;
            border: 1px solid #7F9E56;
            color: #7F9E56;
            cursor: pointer;
        }

        .button-active {
            background-color: #527246;
            border: none;
            color: #fff;
        }
    }

    &__slider {
        display: block;
        margin: 32px 0;
    }

    .slider-hidden {
        display: none;
    }
}
#wes-town__button {
    width: 120px;
    height: 40px;
}

#comf-town__button {
    width: 150px;
    height: 40px;
}
.queue {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    margin-bottom: 32px;
    background-color: #F1F3F5;
    border-radius: 7px;
    width: max-content;

    &-title {
        font-size: 14px;
        font-weight: 500;
        color: #555;
        line-height: 20px;
    }

    &-button {
        width: max-content;
        height: auto;
        font-size: 20px;
        color: #555555;
        border: none;
        border-radius: 7px;
        background-color: #F1F3F5;
        cursor: pointer;
        padding: 15px 41px;
    
        &--active {
            width: max-content;
            height: auto;
            border-radius: 7px;
            font-size: 20px;
            background-color: #527246;
            color: #fff;
            border: none;
            cursor: pointer;
            padding: 15px 41px;
        }
    }
}

@media screen and (max-width: 992px) {
    .build-status {
        &__items {
            flex-direction: column;
            gap: 20px;
        }
    }
}

@media screen and (max-width: 620px) { 
    .build-status__actions {
        flex-direction: column;
        align-items: start;
        gap: 16px;

        &-button {
            width: 100%;
        }

        #wes-town__button, #comf-town__button {
            width: 100%;
        }
    }
    .build-status__title {
        margin-top: 40px !important;
    }
}