.proposal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 80px;
  height: 900px;
  background-image: url('../assets/westPage/images/proposal.jpg');
  background-size: 100% 100%;
  border-radius: 20px;

  &-title {
    font-size: 38px;
    font-weight: 600;
    line-height: 44px;
    text-align: center;
    color: #fff;
    padding-bottom: 180px;
  }
  &-subtitle {
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    color: #fff;
    padding-left: 100px;
  }
  &-actions {
    display: flex;
    padding: 40px 100px 0 100px;
    justify-content: space-between;
    align-items: center;
    &__price {
      display: flex;
      flex-direction: column;
      align-items: center;
      &-text {
        font-size: 62px;
        font-weight: 700;
        line-height: 72px;
        color: #fff;
        border: 3px solid #fff;
        border-radius: 1000px;
        padding: 12px 48px;
      }
      &-subtext {
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        color: #fff;
      }
      &-button {
        width: 512px !important;
        text-transform: uppercase;
        font-size: 28px !important;
        font-weight: 500;
        line-height: 42px !important;

      }
    }
  }
}
.mobActions {
  display: none;
}

@media screen and (max-width: 1366px) {
  .proposal {
    height: 653px;
    margin: 0 56px;

    &-title {
      font-size: 32px;
      line-height: 38px;
    }
    &-subtitle {
      font-size: 36px;
      font-weight: 600;
      line-height: 44px;
    }
    &-actions__price {
      &-text {
        font-size: 48px;
        line-height: 58px;
      }
      &-subtext {
        font-size: 18px;
      }
      &-button {
        font-size: 22px !important;
        line-height: 20px !important;
      }
    }
  }
}

@media screen and (max-width: 1080px) {
  .proposal {
    margin: 0;
    
    &-subtitle {
      padding-left: 5%;
    }

    &-actions {
      padding: 46px 0;
      justify-content: space-around;
    }
  }
}
@media screen and (max-width: 820px) {
  .proposal {
    height: 523px;

    &-title {
      padding: 74px 0 56px 0;
      
    }
  }
}

@media screen and (max-width: 768px) {
  .proposal-actions__price-button {
    width: 429px !important;
  }
  .proposal-title {
    padding: 74px 16px;
  }
}

@media screen and (max-width: 765px) {
  .proposal {
    align-items: center;

    &-subtitle {
      padding: 0;
    }
    &-actions {
      flex-direction: column;
      gap: 8px;
    }
  }
}

@media screen and (max-width: 512px) {
  .proposal {
    background-size: 100%;
    height: 285px;
    gap: 24px;
    margin-bottom: 12px;
    &-title {
      font-size: 22px;
      line-height: 24px;
      font-weight: 500;
      padding: 0;
    }
    &-subtitle {
      text-align: center;
      font-size: 22px;
      font-weight: 600;
      line-height: 28px;
      display: none;
    }
    &-actions {
      padding: 0;
      &__price {
        &-text {
          font-size: 38px;
          font-weight: 600;
          line-height: 44px;
          border: 1px solid #fff;
        }
        &-button {
          width: 288px !important;
          font-size: 14px !important;
          display: none;
        }
      }
    }
  }

  // ====== mobile actions ======
  .mobActions {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding-bottom: 40px;

    &-title {
      font-size: 32px;
      font-weight: 600;
      line-height: 36px;
      color: #181e1a;
    }
    &-button {
      width: 100% !important;
      text-transform: uppercase;
    }
  }
}

@media screen and (max-width: 412px) {
  .proposal {
    height: 250px
  }
}