.plans {
  display: flex;
  justify-content: space-between;
  gap: 36px;
  height: 368px;
  margin-bottom: 112px;
  &-info {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: start;
    justify-content: start;
    padding-bottom: 176px;
    width: 100%;

    &__title {
      font-weight: 500;
      font-size: 26px;
      line-height: 32px;
      text-transform: uppercase;
      color: #527246;
      text-align: center;
    }
    &__text {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }

    &__actions {
      display: flex;
      gap: 20px;
      &-button {
        width: 146px !important;
        height: 40px !important;
        font-size: 14px !important;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }

  &-images {
    width: 100%;
    height: 100%;
    background-image: url('../assets/comfortPage/images/garage.webp');
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 20px;
    &--kitchen {
      width: 100%;
      height: 100%;
      background-image: url('../assets/comfortPage/images/kitchen.webp');
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 20px;
    }
  }
}

.active-plan {
  background-color: #fff !important;
  color: #7F9E56 !important;
  border: 1px solid #7F9E56 !important;
  &:hover {
    transform: scale(1.1);
  }
}

@media screen and (max-width: 868px) {
  .plans {
    flex-wrap: wrap;
    margin-bottom: 300px;
    &-info {
      padding-bottom: 0;
    }
  }
}
@media screen and (max-width: 480px) {
  .plans {
    margin-bottom: 350px;
  }
}