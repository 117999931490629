.header {
    position: fixed;
    // top: 0;
    z-index: 2;
    width: 100%;
    transition: all 0.2s ease-in-out;

    &-bg {
        background: #181E1A;
    }

    &__items {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        &-nav {
            display: flex;
            flex-direction: row;
            gap: 32px;
            align-items: center;
            padding: 10px 0;
        }

        .navigations {
            display: flex;
            align-items: center;
            text-align: center;
            flex-direction: row;
            gap: 32px;
            & li {
                display: flex;
                align-items: center;
                gap: 8px;
            }

            &__action {
                color: #fff;
                font-weight: 400;
                line-height: 24px;
                text-transform: uppercase;
                font-size: 14px;
                cursor: pointer;
                &-span {
                    background-color: #FFEC8B;
                    padding: 4px 10px;
                    font-size: 14px;
                    color: #181E1A;
                    border: 1px solid #FFEC8B;
                    border-radius: 20px;
                    text-align: center;
                }
    
                &::after {
                    content: '';
                    display: block;
                    width: 0;
                    height: 1px;
                    background: #fff;
                    transition: width .3s;
                }
    
                &:hover::after {
                    width: 100%;
                    transition: width .3s;
                }
            }
        }

        &-contacts {
            display: flex;
            align-items: center;
            gap: 32px;

            .contacts__number {
                display: flex;
                align-items: center;
                gap: 8px;
                color: #fff;
                font-weight: 400;
                line-height: 24px;
                font-size: 14px;
            }

            .contacts__button {
                width: 230px;
                height: 65px;
                font-size: 14px;
                font-weight: 400;
            }
        }
    }
}

@media screen and (max-width: 1366px) {
    .navigations {
        gap: 22px !important;

        &__action {
            font-size: 12px !important;
        }
    }
}