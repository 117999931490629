.main-block {
  width: 100%;
  height: 680px;
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  cursor: pointer;
}

@media screen and (max-width: 650px) {
  .main-block__image {
    height: 220px;
  }
}