.contacts {
    padding: 60px 0 112px 0;
    display: flex;
    gap: 220px;
    justify-content: center;
    align-items: center;

    &-container {
        display: flex;
        flex-direction: column;
        padding-left: 80px;
        gap: 32px;
    }

    &__item {
        display: flex;
        gap: 16px;

        &-icon {
            width: 48px;
            height: 48px;
        }
    }

    &-title {
        color: #555;
    }
    &-subtitle {
        color: #181E1A;
        font-size: 18px;
        font-weight: 500;
    }

    &-button a{
        color: #fff;
    }
}

@media screen and (max-width: 1440px) {
    .contacts {
        gap: 36px;
    }
}

@media screen and (max-width: 820px) {
    .contacts {
        flex-direction: column;
        padding: 40px 0 0 0;

        &-container {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 0 56px;
        }

        &-button {
            width: -webkit-fill-available !important;
        }
    }
}

@media screen and (max-width: 480px) {
    .contacts {
        &-container {
            padding: 0 16px;
        }

        &-subtitle {
            word-break: break-all;
        }

        &__item-icon {
            width: 48px;
            height: 48px;
        }
    }
}