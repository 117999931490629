.west-action {
    padding-bottom: 16px;
}
.west-info {
    padding-bottom: 0px;
}

.houses {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 120px;
    align-items: center;
    padding: 60px 0 60px 0;
}

.termination-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 24px;

    &__subtitle {
        font-size: 22px;
        font-weight: 500;
        line-height: 24px;
        color: #555;
        text-align: center;
        padding-bottom: 60px;
    }
}

.partners {
    display: flex;
    flex-direction: column;
    gap: 60px;
    padding-top: 60px;

    &__info {
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: center;

        &-title {
            color: #7F9E56;
            text-align: center;
            font-size: 34px;
            font-weight: 600;
            line-height: 40px;
        }

        &-text {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: #555;
            text-align: center;
            margin-bottom: 4px;
        }

        &-link {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
        }
        &-action {
            color: #181E1A;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            text-align: center;
        }
    }
}

@media screen and (max-width: 1920px) {
    .west-action {
        padding-left: 120px;
    }

    .bedroom {
        margin: 0 200px;
    }
}
@media screen and (max-width: 1650px) {
    .west-action {
        padding-left: 60px;
    }
    .bedroom {
        margin: 0 120px;
    }
}
@media screen and (max-width: 1440px) {
    .west-action {
        padding-left: 0px;
    }
    .houses {
        justify-content: space-around;
        gap: 80px;
    }
    .bedroom {
        margin: 0 80px;
    }
}

@media screen and (max-width: 1024px) {
    .west-slider {
        flex-direction: column;
        width: 100%;
    }

    .houses {
        flex-direction: column;
        gap: 20px;
    }
    .west-plan {
        align-items: center;
    }
    
    .termination {
        width: 70%;
    }
}

@media screen and (max-width: 820px) {
    
    .bedroom {
        background-size: 100% 480px;
        height: 480px;
        margin: 0 56px;
        &-container {
            height: 480px;
        }
    }

    .termination {
        width: 100%;
    }
}

@media screen and (max-width: 480px) {
    .bedroom {
        background-size: 100% 280px;
        height: 280px;
        margin: 0 16px;

        &-container {
            height: 280px;

            &__title {
                font-size: 23px;
                line-height: 28px;
            }
        }
    }
    .houses {
        gap: 60px;
        padding-bottom: 60px;
    }
}