.house-type {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__img {
        max-width: 100%;
        object-fit: cover;
        height: 520px;
        border-radius: 20px;
        margin-bottom: 24px;
    }

    &__type {
        color: #181E1A;
        font-size: 26px;
        font-weight: 600;
        line-height: 32px;
        padding-bottom: 16px;
    }

    &__info {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-around;
        align-items: center;

        &-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 4px;
        }

        &-main {
            color: #181E1A;
            font-size: 26px;
            font-weight: 600;
            line-height: 32px;
        }

        &-subinfo {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: #555;
        }
    }
}

@media screen and (max-width: 1366px) {
    .house-type__img {
        height: 453px;
    }
}

@media screen and (max-width: 1024px) {
    .house-type {
        width: 80%;
    }
}

@media screen and (max-width: 580px) {
    .house-type__info-main {
        font-size: 20px;
    }
}

@media screen and (max-width: 480px) {
    .house-type {
        width: 100%;

        &__img {
            height: 330px;
        }
    }
}
@media screen and (max-width: 380px) {
    .house-type {
        &__img {
            height: 250px;
        }

        &__info-main {
            font-size: 20px;
        }
    }
}