.banner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background: rgba(24, 30, 26, 0.5);
    margin-bottom: 1px;

    &__image {
        width: 100%;
        z-index: -1;
        height: 100vh;
        object-fit: cover;
    }

    &__titles {
        position: absolute;
        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;

        &-liltext {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
            background-color: #FFEC8B;
            color: #181E1A;
            font-size: 18px;
            font-weight: 500;
            line-height: 20px;
            padding: 10px;
            border: none;
            border-radius: 100px;
        }

        &-main {
            font-size: 100px;
            color: #fff;
            font-weight: 600;
            line-height: 120px;
            text-transform: uppercase;
            text-align: center;
        }

        &-subtitle {
            font-size: 26px;
            font-weight: 500;
            line-height: 32px;
            text-transform: uppercase;
            color: #fff;
        }

    }

    &__action {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        height: 64px;
        padding: 0 64px;
        border: none;
        border-radius: 100px;
        background-color: #527246;
        cursor: pointer;
        bottom: 0;
        margin-bottom: 100px;
        color: #fff;
        font-size: 28px;
        font-weight: 500;
        line-height: 20px;
        text-transform: uppercase;
        transition: all 0.1s ease-in-out;

        &:hover {
            background-color: #7F9E56 !important;
        }
    }
}

@media screen and (max-width: 1280px) {
    .banner {
        &__titles {
            gap: 16px;
        }
        &__action {
            margin-bottom: 50px;
            padding: 0 20px;

            & a {
                font-size: 22px;
            }
        }
    }
}

@media screen and (max-width: 1130px) {
    .banner {
        &__titles {
            &-main {
                font-size: 44px;
                line-height: 52px;
            }
        }

        &__action {
            margin-bottom: 80px;
            font-size: 14px;
        }
    }
}

@media screen and (max-width: 480px) {
    .banner {
        &__titles {
            &-main {
                font-size: 27px;
                line-height: 32px;
            }
            &-subtitle {
                font-size: 22px;
                line-height: 24px;
                text-align: center;
            }
        }

        &__action {
            height: 48px;
            margin-bottom: 100px;
            width: 288px;
            padding: 0;
        }
    }
}

@media screen and (max-height: 720px) {
    .banner {
        &__titles {
            &-main {
                font-size: 54px;
                line-height: 55px;
            }
            &-subtitle {
                font-size: 22px;
                line-height: 24px;
                text-align: center;
            }
        }

        &__action {
            font-size: 22px;
        }
    }
}

@media screen and (max-height: 667px) {
    .banner {
        &__titles {
            gap: 22px;
            &-main {
                font-size: 34px;
                line-height: 35px;
            }
            &-subtitle {
                font-size: 18px;
                line-height: 20px;
                text-align: center;
            }
        }

        &__action {
            font-size: 22px;
            margin-bottom: 25px;
        }
    }
}