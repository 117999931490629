$text-color: #969F9A;
$title-color: #7F9E56;


.about-comfort {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-image: url('../assets/comfortPage/images/comfort-page-banner.webp');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    &-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        &__info {
            z-index: 1;
        }
    }

    &-shadow {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(#181e1a, 0.7);
        top: 0;
        left: 0;
    }
}

.text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    &__title-comfort {
        font-weight: 600;
        font-size: 100px;
        line-height: 120px;
        text-transform: uppercase;
        color: #fff;
        padding-bottom: 20px;
        text-align: center;
        letter-spacing: -0.6px;
    }

    &__text-comfort {
        font-size: 26px;
        text-align: center;
        text-transform: uppercase;
        font-weight: 500;
        color: #fff;
        padding-bottom: 28px;
        line-height: 32px;
    }
}
.right-border-item {
    border-right: 1px solid #fff;
    padding-right: 30px;
    margin-right: 30px;
}

.about-button-comfort {
    text-transform: uppercase;
    width: 308px !important;
    font-size: 25px !important;
    line-height: 20px !important;
    margin-bottom: 124px !important;
}

@media screen and (max-width: 920px) {
    .text-container__title-comfort {
        font-size: 80px;
    }
}
@media screen and (max-width: 768px) {
    .text-container__title-comfort {
        font-size: 52px;
        line-height: 72px;
    }
}
@media screen and (max-width: 768px) {
    .text-container__title-comfort {
        font-size: 36px;
        line-height: 32px;
    }
    .text-container__text-comfort {
        font-size: 22px;
        line-height: 24px;
    }
}
@media screen and (max-width: 768px) {
    .about-button-comfort {
        font-size: 14px !important;
        line-height: 20px !important;
        
    }
}
@media screen and (max-height: 600px) {
    .about-button-comfort {
        margin-bottom: 25px !important;
    }
}