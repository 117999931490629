$text-color: #969F9A;
$title-color: #7F9E56;

.video-container {
    position: absolute;
    width: 100%;
    height: 100vh;
    background: rgba(24, 30, 26, 0.7);
}
.main-video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    position: absolute;
}
.about {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-image: url('../assets/images/main-banner.webp');
    background-position: center;
    &-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        &__info {
            z-index: 1;
        }
    }

    &-shadow {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(#181e1a, 0.6);
        top: 0;
        left: 0;
    }
}

.text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    &__title {
        font-weight: 700;
        font-size: 57px;
        line-height: 68px;
        text-transform: uppercase;
        color: $title-color;
        padding-bottom: 20px;
        text-align: center;
        letter-spacing: -0.6px;
    }

    &__text {
        font-size: 20px;
        text-align: center;
        text-transform: uppercase;
        font-weight: 500;
        color: #fff;
        padding-bottom: 28px;
    }

    &__actions {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 36px;
    }
}
.right-border-item {
    border-right: 1px solid #fff;
    padding-right: 30px;
    margin-right: 30px;
}

.about-button {
    text-transform: uppercase;
    width: 300px !important;
}

@media screen and (max-width: 768px) {
    .about {
        background-position-x: 600px;
    }
    .text-container {
        width: 100%;
        &__title {
            font-size: 40px;
            font-weight: 600;
            line-height: 52px;
            letter-spacing: -0.5px;
            padding-bottom: 15px;
        }

        &__text {
            font-size: 16px;
        }
    }
}

@media screen and (max-width: 620px) {
    .video-container {
        display: none;
    }
    .text-container {
        &__title {
            font-size: 36px;
            font-weight: 600;
            line-height: 48px;
            letter-spacing: -0.5px;
        }

        &__text {
            font-size: 14px;
            line-height: 24px;
            color: #969F9A;
        }

        &__actions {
            flex-direction: column;
            gap: 15px;
        }
    }

    .about-button {
        width: 100% !important;
    }
}

@media screen and (max-width: 413px) {
    .text-container {
        &__title {
            font-size: 24px;
            font-weight: 700;
            line-height: 32px;
            letter-spacing: -0.3px;
        }

        &__text {
            font-size: 14px;
            line-height: 24px;
            color: #969F9A;
        }
    }
    .about-button {
        height: 50px !important;
        font-size: 12px !important;
    }
}