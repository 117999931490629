.plan {
    margin-top: 60px;
    display: flex;
    flex-direction: row;

    &-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        padding-left: 34px;
        gap: 24px;

        &__title {
            color: #527246;
            text-transform: uppercase;
            font-weight: 500;
            font-size: 26px;
            line-height: 36px;
        }

        &__text {
            color: #555555;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
        }

        &__cards {
            display: flex;
            flex-direction: column;
            gap: 32px;
            width: 100%;

            &-box {
                display: flex;
                flex-direction: row;
                align-items: start;
                gap: 32px;
            }
        }

        &__card {
            display: flex;
            flex-direction: column;
            gap: 8px;
            padding: 16px;
            background-color: #F7F7F7;
            border-radius: 8px;
            align-items: start;
            width: 50%;

            &-title {
                color: #555555;
                font-size: 18px;
                font-weight: 500;
                line-height: 24px;
            }

            &-info {
                color: #181E1A;
                font-size: 18px;
                font-weight: 500;
                line-height: 24px;
            }
        }
    }
}

.beskid-plan-swiper {
    width: 622px;
    height: 622px;
    border-radius: 20px;
}

@media screen and (max-width: 1280px) {
    .beskid-plan-swiper {
        width: 600px;
        height: 500px;
    }
}
@media screen and (max-width: 910px) {
    .plan {
        flex-direction: column-reverse;
        gap: 24px;
        width: 100%;

        &-info {
            padding: 0;
        }
    }
    .beskid-plan-swiper {
        width: 100%;
        height: 500px;
    }
}

@media screen and (max-width: 620px) {
    .beskid-plan-swiper {
        height: 400px;
    }
}

@media screen and (max-width: 470px) {
    .beskid-plan-swiper {
        height: 280px;
    }
}