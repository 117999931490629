.west-page-swiper {
    width: 600px;
    height: 368px;
    border-radius: 20px;
    margin: 0;
    cursor: pointer;
}

.back-yard-swiper {
    width: 623px;
}


@media screen and (max-width: 1024px) {
    .west-page-swiper {
        width: 80%;
        height: 444px;
    }
}

@media screen and (max-width: 820px) {
    .west-page-swiper {
        width: 100%;
        height: 444px;
    }
}

@media screen and (max-width: 480px) {
    .west-page-swiper {
        height: 228px;
    }
    .back-yard-swiper {
        height: auto;
    }
}