$base-font: 'Work Sans', sans-serif;
$main-color: #181E1A;
$title-color: #7F9E56;
$button-color: #527246;
$text-color: #969F9A;

.complexes {
    margin: 110px 0 110px 0;
    &-up {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 36px;
        margin: 60px 0 60px 0;
    }
    &-down {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 60px;
    }
}

@media screen and (max-width: 1020px) {
    .complexes-up {
        flex-wrap: wrap;
        margin-bottom: 40px;
    }
    .complexes-down {
        padding-top: 40px;
    }
}