.adventage {
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-radius: 20px;
    overflow: hidden;
    height: 433px;


    &__box {
        display: flex;
        flex-direction: column;
        align-items: center;
        
        &-image {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            // width: auto !important;
        }
    
        &-text {
            color: #fff;
            font-size: 23px;
            font-weight: 500;
            line-height: 28px;
            text-align: center;
            transform: translateY(300px);
        }
    }
    .bordered {
        border-radius: 20px 0 0 20px;

        &-reverse {
            border-radius: 0 20px 20px 0;
        }
    }
}

.grad {
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(24, 30, 26, 0.00) 0%, rgba(24, 30, 26, 0.65) 65.1%);
}

@media screen and (max-width: 1048px) {
    .adventage {
        height: 333px;

        &__box {

            &-text {
                transform: translateY(250px);
                font-size: 20px;
                padding: 0 5px;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .adventage {
        height: 100%;
    }
}

@media screen and (max-width: 480px) {
    .adventage {
        height: 280px;

        &__box {

            &-text {
                font-size: 20px;
                line-height: 24px;
                transform: translateY(200px);
            }

            &-image {
                width: 100% !important;
            }
        }
    }
}