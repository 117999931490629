.block-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 32px;

  &__title {
    font-weight: 600;
    font-size: 34px;
    line-height: 40px;
    color: #181E1A;
  }

  &__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #555;
    width: 40%;
  }
}

.special-proposal {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFEC8B;
  width: 100%;
  padding: 8px 0;
  z-index: 5;

  &__text {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #181E1A;
  }
  &__action {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 12px;
  }
}


.private-ground {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 112px 0 60px 0;
  gap: 16px;

  &__proposal {
    background-color: #FFEC8B;
    border-radius: 100px;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #181E1A;
  }
  &__title {
    font-size: 34px;
    font-weight: 600;
    line-height: 40px;
    color: #181E1A;
    text-align: center;
  }
  &__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #555;
    text-align: center;
  }

}

.propose {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #181E1A;
  border-radius: 20px;
  padding: 40px;
  margin-top: 112px !important;

  &-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 18px;

    &__text {
      text-align: center;
      max-width: 70%;
    }
  }
  &-title{
    font-size: 34px;
    line-height: 40px;
    color: #fff;
    font-weight: 600;
    text-align: center;
  }

  &-boxes {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 36px;
    transform: translateY(70px);
  }
  
  &-box {
    display: flex;
    flex-direction: column;
    width: 512px;
    height: 397px;
    padding: 32px;
    gap: 36px;
    color: #181E1A;
    background-color: #FFFFFF;
    border-radius: 12px;
    box-shadow: 0px 3px 10px #80808052;

    &__title {
      font-size: 24px;
      font-weight: 600;
      line-height: 28px;
    }

    &__info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;

      &-text {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #000;
      }

      &-subtext {
        font-weight: 500;
        font-size: 18px;
        color: #555;
        line-height: 22px;
      }
    }
  }
  &-span {
    background-color: #FFEC8B;
    border-radius: 999px;
    padding: 4px 8px;
  }
  &-price {
    font-size: 22px;
    font-weight: 600;
    line-height: 28px;
  }
}

.contact-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 32px;

  &__text {
    font-weight: 500;
    font-size: 26px;
    line-height: 32px;
    color: #555;
  }

  &__phone {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    padding-right: 15%;

    &-box {
      &-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #555;
      }
      &-tel {
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        color: #181E1A;
      }
    }
  }
}

.insaltment {
  background-image: url('../assets/comfortPage/images/instalments.webp');
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: start;

  &-box {
    background-color: #181E1A;
    width: 50%;
    height: 100%;
    border-radius: 20px;
    padding: 179px 36px;

    &__info {
      display: flex;
      flex-direction: column;
      gap: 16px;

      &-zap {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #FFEC8B;
        border: 1px solid #FFEC8B;
        border-radius: 100px;
        padding: 8px 12px;
        display: flex;
        align-items: center;
        gap: 8px;
        width: fit-content;
      }

      &-title {
        font-weight: 500;
        font-size: 26px;
        line-height: 32px;
        color: #527246;
      }

      &-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #969F9A;

      }
    }
  }
}
@media screen and (min-width: 1440px) {
  .comfort-action {
    margin: 60px 0 15px 0 !important;
  }
  .west-action {
    padding-left: 80px;
  }
}
@media screen and (max-width: 1200px) {
  .insaltment-box {
    padding: 110px 36px;
  }
}
@media screen and (max-width: 1024px) {
  .propose {
    &-boxes {
      flex-direction: column;
    }
  }
}
@media screen and (max-width: 920px) {
  .insaltment {
    flex-direction: column;
    justify-content: flex-end;
    height: 70vh;
    background-position: -140px -240px;
    &-box {
      height: fit-content;
      width: 100%;
      padding: 36px;
    }
  }

  .contact-block__phone {
    padding: 0;
  }
}

@media screen and (max-width: 768px) {
  .block-info {
    flex-wrap: wrap;
    gap: 24px;

    &__text {
      width: 100%;
    }
  }
  .comfort-action {
    margin: 40px 0 0 56px !important;
  }

  .private-ground {
    padding: 40px 0 40px 0;
  }


  .contact-block__text {
    width: 50%;
  }
}
@media screen and (max-width: 716px) {
  .propose {
    &-box {
      width: 100%;
      height: max-content;

      &__button {
        width: 100% !important;
      }
    }
  }
}
@media screen and (max-width: 620px) {
  .comfort-action {
    margin: 40px 0 0 16px !important;
  }
  .advantage-comfort-title {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .contact-comfort-title {
    padding: 40px 0 !important;
  }
  .comfort-last-block {
    margin-bottom: 60px !important;
  }

  .propose-box__button {
    height: 48px !important;
    font-size: 14px !important;
  }
}
@media screen and (max-width: 540px) {
  .block-info {
    &__title {
      font-size: 23px;
      line-height: 28px;
    }
    &__text {
      font-size: 14px;
      line-height: 20px;
    }
  }
  .private-ground {
    &__title {
      font-size: 23px;
      line-height: 28px;
    }
  }
  .insaltment-box {
    padding: 16px;
  }
  .insaltment-box__info-title {
    font-size: 22px;
    line-height: 24px;
  }
  .insaltment-box__info-text {
    font-size: 16px;
    line-height: 20px;
  }
  .insaltment-box__info-button {
    width: 100% !important;
  }

  .contact-block {
    flex-wrap: wrap;
    gap: 20px;
    &__text {
      width: 100%;
    }
  }

  .special-proposal {
    font-size: 14px;
    flex-direction: column;
  }

  .propose {
    padding: 16px;
    &-box {
      padding: 18px;
      gap: 16px;
    }
    &-title {
      font-size: 23px;
    }
    &-block__text {
      font-size: 14px;
      max-width: 100%;
    }
    &-box__info {
      &-text {
        font-size: 18px;
      }
      &-subtext {
        font-size: 14px;
      }
    }
    &-boxes {
      gap: 16px;
      transform: translateY(40px);
    }
    &-span {
      font-size: 14px;
    }
    &-price {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 410px) {
  .propose {
    padding: 12px;
    &-box {
      padding: 12px;
    }
  }
  .insaltment {
    height: 85vh;
  }
}