.mountains {
  background-image: linear-gradient(rgba(24, 30, 26, 0.4), rgba(24, 30, 26, 0.4)), url(../assets/beskidPage/images/mountains.webp);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 65vh;

  &-titles {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 55px;
    transform: translateY(100px);

    &__main {
      color: #FFF;
      font-size: 55px;
      font-weight: 600;
      text-align: center;
    }
    &__sub {
      color: #FFF;
      font-size: 18px;
      font-weight: 400;
      text-align: center;
    }
  }

  &-cards {
    display: flex;
    align-items: center;
    align-items: flex-end;
    height: 65vh;
    gap: 24px;
    width: 90%;
    transform: translateY(65px);

    &-tabs {
      display: none;
    }
  }
  &-card {
    display: flex;
    flex-direction: column;
    background-color: #F7F7F7;
    border-radius: 12px;
    align-items: start;
    padding: 24px;
    width: 33.33%;
    gap: 8px;
    color: #555555;
  }
}

@media screen and (max-width: 820px) {
  .mountains-titles {
    &__main {
      font-size: 34px;
    }
    &__sub {
      font-size: 14px;
      width: 75%;
    }
  }
  .mountains-card-text {
    font-size: 12px;
  }
}

@media screen and (max-width: 800px) {
  .mountains {
    height: 100%;
  }
  .mountains-titles {
    transform: translateY(0);
  }
  .mountains-cards {
    display: none;
    &-tabs {
      display: flex;
      gap: 8px;
      padding-top: 24px;
    }
  }
}

@media screen and (max-width: 620px) {
  .mountains-cards-tabs {
    flex-direction: column;
  }
  .mountains-card {
    width: 100%;
    flex-direction: row;
    align-items: center;
  }
}

@media screen and (max-width: 520px) {
  .mountains-titles {
    padding: 40px 16px;
    &__main {
      font-size: 22px;
    }
    &__sub {
      font-size: 14px;
      width: 100%;
    }
  }
  .mountains-card-text {
    font-size: 12px;
  }
}