.prev {
  width: 100%;
  height: 400px;
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  cursor: pointer;
  margin-top: 70px;
  
  &-body {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 100%;
      z-index: 1;
      padding: 20px;
      border-radius: 20px;
      
      &__text {
        display: flex;
        flex-direction: column;
        align-items: start;
      }

      &__header {
          color: #fff;
          font-size: 34px;
          line-height: 40px;
          font-weight: 600;
      }
      &__type {
          color: #fff;
          font-size: 18px;
          font-weight: 500;
          line-height: 28px;
      }
  }

  &-sold {
    position: absolute;
    color: #181E1A;
    font-weight: 500;
    font-size: 23px;
    background-color: #FFEC8B;
    border-radius: 20px;
    gap: 7px;
    width: fit-content;
    padding: 8px 12px;
    margin-top: 20px;
    margin-left: 20px;
  }
}


@media screen and (max-width: 1200px) {
  .prev {
    width: 100%;
    height: 280px;

    &-body {
      padding: 16px;

      &__text {
        gap: 0;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .prev {
    &-body {
      padding: 12px;
    }
    &-sold {
      margin-top: 12px;
      margin-left: 12px;
    }
  }
}
@media screen and (max-width: 768px) {
  .complex {
    height: 418px;

    &-body {
      padding: 20px;
      &__header {
        font-size: 23px;
        line-height: 28px;
      }
      &__location {
        font-size: 20px;
        line-height: 24px;
        margin-right: 20px;
      }
      &__type {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}
@media screen and (max-width: 622px) {
  .complex {
    width: 380px;
  }
}
@media screen and (max-width: 422px) {
  .complex {
    width: 280px;
  }
}