.modal {
    position: fixed;
    opacity: 0;
    pointer-events: none;
    
    &--active {
        opacity: 1;
        pointer-events: all;
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100vw;
        height: 100vh;
        background: rgba($color: #000000, $alpha: 0.7);
        transition: 0.5s;
        z-index: 2;
    }

    &__container {
        display: flex;
        flex-direction: column;
        border-radius: 20px;
        background-color: #161B18;
        padding: 0 45px;
    }

    &__close {
        display: flex;
        flex-direction: row;
        justify-content: end;
        padding: 20px 0;

        &-icon {
            width: 24px;
            height: 24px;
            cursor: pointer;
        }
    }

    &__title {
        text-align: center;
        font-weight: 500;
        color: #fff;
        font-size: 23px;
        line-height: 28px;
    }

    &__actions {
        margin: 50px 0 65px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 32px;

        &--column {
            display: flex;
            gap: 32px;
            flex-direction: column;
            align-items: center;
            margin: 50px 0 65px 0;
        }

        &-action {
            width: 360px;
            height: 65px;
        }
    }
}

@media screen and (max-width: 820px) {
    .modal__actions-action {
        width: 300px !important;
    }
}

@media screen and (max-width: 720px) {
    .modal__actions {
        flex-direction: column;

        &-action {
            width: 420px !important;
        }
    }
}

@media screen and (max-width: 481px) {
    .modal__actions-action {
        width: 380px !important;
    }
    .modal__container {
        padding: 0 22px;
    }
}
@media screen and (max-width: 380px) {
    .modal__actions-action {
        width: 100% !important;
        height: 45px !important;
    }
    .modal__container {
        padding: 0 16px;
    }
}