.moreabout {
  display: flex;
  flex-direction: column;

  &-cards {
    display: flex;
    gap: 36px;
    margin-bottom: 60px;
    justify-content: center;
  }

  &-card {
    width: 33%;
    max-width: 455px;
    background-color: #181E1A;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    padding: 24px;
    gap: 12px;
    border-radius: 20px;
  
    &__text {
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #969F9A;
    }
  }

  &-content {
    display: flex;
    gap: 36px;
    justify-content: center;

    &__info {
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 24px;
      max-width: 50%;

      &-title {
        font-weight: 600;
        font-size: 26px;
        line-height: 32px;
        color: #527246;
      }
      &-list {
        display: flex;
        flex-direction: column;
        gap: 24px;
      }
      &-item {
        display: flex;
        gap: 16px;
        align-items: start;
        &-text {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #555;
        }
      }
    }
  }
}
@media screen and (max-width: 1370px) {
  .moreabout-content {
    &__img {
      width: 50%;
      height: 50%;
    }
  }
  .moreabout-content__info-list {
    gap: 4px;
  }
}
@media screen and (max-width: 1024px) {
  .moreabout-content {
    flex-wrap: wrap;
    &__info {
      max-width: 100%;
      &-title {
        font-weight: 500;
      }
    }
    &__img {
      width: 100%;
      height: 100%;
    }
  }
}
@media screen and (max-width: 820px) {
  .moreabout-cards {
    gap: 16px;
    margin-bottom: 40px;
  }
  .about-comfort-title {
    padding: 40px 0 40px 0 !important;
  }
}
@media screen and (max-width: 650px) {
  .moreabout-cards {
    flex-direction: column;
    align-items: center;
  }
  .moreabout-card {
    width: 100%;
  }
}