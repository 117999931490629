#app {
    height: 100%;
  }
  html,
  body {
    position: relative;
    height: 100%;
  }
  
  .buildStatusSlider {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: start;
    font-size: 18px;
    background: #fff;
    /* Center slide text vertically */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .buildStatusImg {
    display: block;
    width: 533px !important;
    height: 400px !important;
    /* object-fit: cover; */
    border-radius: 10px;
    cursor: pointer;
  }

  @media screen and (max-width: 1440px) {
     .buildStatusImg {
      width: 100% !important;
      height: 300px !important;
     }
  }

  @media screen and (max-width: 1280px) {
    .buildStatusImg {
     width: 100% !important;
     height: 280px !important;
    }
 }

 @media screen and (max-width: 1080px) {
  .buildStatusImg {
   width: 100% !important;
   height: 250px !important;
  }
}

@media screen and (max-width: 1024px) {
  .buildStatusImg {
   width: 100% !important;
   height: 210px !important;
  }
}

@media screen and (max-width: 824px) {
  .buildStatusImg {
   width: 100% !important;
   height: 250px !important;
  }
}

@media screen and (max-width: 768px) {
  .buildStatusImg {
   width: 100% !important;
   height: 220px !important;
  }
}

@media screen and (max-width: 620px) {
  .buildStatusImg {
   width: 100% !important;
   height: 300px !important;
  }
}

@media screen and (max-width: 428px) {
  .buildStatusImg {
   width: 100% !important;
   height: 270px !important;
  }
}

  .status-info__container {
    max-width: 403px;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding-left: 5px;
    padding-top: 12px;
    gap: 12px;
  }

  .status-info__date {
    font-size: 16px;
    line-height: 24px;
    color: #555;
  }

  .status-info__title {
    font-size: 23px;
    font-weight: 400;
    line-height: 28px;
    color: #527246;
    text-transform: uppercase;
  }

  .status-info__text {
    text-align: left;
    font-size: 16px;
    line-height: 24px;
    color: #555;
  }