.news {
    margin-bottom: 110px;
    margin-top: 110px;
    &__container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__item {
        display: flex;
        flex-direction: column;
        padding-top: 70px;
        gap: 12px;

        &-image {
            border-radius: 10px;
            height: 235px !important;
        }

        &-info {
            display: flex;
            flex-direction: column;
            gap: 12px;
            align-items: start;
            padding-left: 5px;
        }
    }

    &-date {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.2px;
        color: #555;
        text-align: start;
    }

    &-title {
        font-size: 23px;
        font-weight: 500;
        line-height: 28px;
        color: #527246;
        text-align: start;
    }

    &-text {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.2px;
        color: #555;
        text-align: start;
    }
}

@media screen and (max-width: 992px) {
    .news {
        margin-bottom: 40px;
        margin-top: 40px;
        &__container {
            flex-wrap: wrap;
        }

        &__item {
            flex: 0 45%;
            padding-top: 40px;
        }

        &-title {
            font-size: 20px;
            line-height: 24px;
        }
    }
}

@media screen and (max-width: 480px) {
    .news {
        &__container {
            flex-direction: column;
            flex-wrap: nowrap;
        }

        &__item {
            flex: auto;
            width: 100%;
        }
    }
}