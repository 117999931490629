.complex {
  width: 622px;
  height: 622px;
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  
  &-body {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 100%;
      z-index: 1;
      padding: 36px;
      border-radius: 20px;
      
      &__text {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }

      &__header {
          color: #fff;
          font-size: 34px;
          line-height: 40px;
          font-weight: 600;
      }
      &__location {
          color: #181E1A;
          font-weight: 500;
          font-size: 23px;
          line-height: 28px;
          background-color: #fff;
          border-radius: 20px;
          display: flex;
          align-items: center;
          gap: 7px;
          width: fit-content;
          padding: 8px 12px;
      }
      &__type {
          color: #fff;
          font-size: 18px;
          font-weight: 500;
          line-height: 28px;
      }
  }
}

@media screen and (max-width: 1200px) {
  .complex {
    height: 500px;
  }
}

@media screen and (max-width: 1024px) {
  .complex {
    height: 450px;
    width: 450px;
  }
}
@media screen and (max-width: 768px) {
  .complex {
    height: 418px;

    &-body {
      padding: 20px;
      &__header {
        font-size: 23px;
        line-height: 28px;
      }
      &__location {
        font-size: 20px;
        line-height: 24px;
        margin-right: 20px;
      }
      &__type {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}
@media screen and (max-width: 622px) {
  .complex {
    width: 380px;
  }
}
@media screen and (max-width: 422px) {
  .complex {
    width: 280px;
  }
}