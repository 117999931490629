.action {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #969F9A;
    border-radius: 100px;
    width: fit-content;
    padding: 12px 20px;
    gap: 8px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &-icon {
        stroke: #555555;
        width: 20px;
        height: 20px;
        &:hover {
            stroke: #fff;
        }
    }

    &-text {
        color: #555555;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
    }

    &:hover {
        background-color: #555555;

        .action-text, .action-icon {
            color: #fff;
            stroke: #fff;
        }
    }
}

.title-container {
    padding-top: 60px;
}

.title {
    display: flex;
    align-items: center;
    font-size: 34px;
    font-weight: 600;
    line-height: 40px;
    color: #181E1A;
    letter-spacing: -0.35px;
    text-align: start;
    padding-top: 18px;
}

.location {
    display: flex;
    align-items: center;
    gap: 8px;
    padding-top: 16px;
    
    &-icon {
        width: 24px;
        height: 24px;
        color: #555555;
    }

    &-text {
        color: #555555;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
    }
}

.info {
    display: flex;
    flex-direction: column;
    gap: 112px;
    padding-bottom: 112px;

    &-container {
        padding-top: 112px;
        display: flex;
        flex-direction: row;
        gap: 36px;
        justify-content: center;
        align-items: center;
    }

    &-description {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
        gap: 24px;

        &__title {
            color: #527246;
            font-size: 26px;
            font-weight: 500;
            line-height: 32px;
            text-transform: uppercase;
        }

        &__text {
            color: #555555;
            line-height: 24px;
        }
    }
}

.group-images {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    overflow: hidden;

    &__img {
        width: 33.33%;
    }
}

.plannings-type {
    display: flex;
    flex-direction: row;
    gap: 36px;
    justify-content: center;
    padding: 0 110px;
}

.adventages {
    display: flex;
    background-image: url('../assets/beskidPage/images/invest-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 122px;
    padding: 60px 0;


    &__container {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 16px;
    }

    &__title {
        font-size: 34px;
        font-weight: 600;
        line-height: 40px;
        color: #fff;
        text-align: center;
        padding-bottom: 60px;
    }
}

@media screen and (max-width: 1920px) {
    .beskid-title {
        padding-left: 120px;
        padding-top: 60px;
    }
    .title-container {
        padding: 100px 0 30px 0;
    }
}
@media screen and (max-width: 1650px) {
    .beskid-title {
        padding-left: 60px;
    }
}
@media screen and (max-width: 1440px) {
    .beskid-title {
        padding-left: 0px;
    }
}
@media screen and (max-width: 1366px) {
    .group-images {
        height: 512px;
    }
}



@media screen and (max-width: 1024px) {
    .info-description {
        width: 80%;
    }
    .card {
        width: 35vw;
    }
    .group-images {
        height: auto;
    }

}

@media screen and (max-width: 820px) {
    // ======= sliders section =========
    .info {
        gap: 80px;

        &-container {
            flex-direction: column;
        }

        &-description {
            width: 100%;
            gap: 16px;

            &__button {
                margin-top: 8px;
            }
        }
        
    }

    .second-slider__container {
        flex-direction: column-reverse;
    }

    // planing types section
    .card {
        width: 100%;
    }
    .plannings-type {
        flex-direction: column;
        padding: 0;
    }
}


@media screen and (max-width: 768px) {
    .title {
        text-align: left;
        font-size: 34px;
        line-height: 48px;
    }

    .title-container {
        padding-top: 40px;
    }

    .info {
        padding-bottom: 30px;
    }
}

@media screen and (max-width: 480px) {
    .title {
        font-size: 23px;
        font-weight: 600;
        line-height: 28px;
    }

    .title-container {
        padding-top: 32px;
    }


    // ======= sliders section =======
    .info {
        gap: 40px;

        &-container {
            gap: 12px;
            padding-top: 28px;
        }

        &-description {

            &__title {
                font-size: 22px;
            }

            &__button{
                width: 100% !important;
            }
        }
    }

    .group-images {
        display: none;
    }

    // ===== planing types section =====
    .plannings-type {
        gap: 20px;
    }

    // ==== adventages section ====
    .adventages {
        padding-top: 32px;
    }
}